<template>
  <div id="app" class="ticket">
    <!-- Loading Spinner -->
    <div class="spinner" v-if="isLoading">
      <div><div></div></div>
    </div>
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a @click.prevent="$router.push('/tickets')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>票券</span>
      <div class="right"></div>
    </section>
    <!-- Footer -->
    <section class="foot">
      <button
        v-if="ticket && ticket.status === 'pickedup' && !isRedeemed"
        @click.prevent="showConfirmPop()"
      >
        <span>現場使用</span>
      </button>
      <button
        v-else
        class="secondary"
        @click.prevent="$router.push('/tickets')"
      >
        <span>返回我的票券</span>
      </button>
    </section>
    <section class="main" v-if="ticket">
      <!-- 票券 -->
      <div class="coupon">
        <div class="dot left"></div>
        <div class="dot right"></div>
        <div class="label" v-if="isRedeemed || ticket.status === 'redeemed'">
          <span>已兌換</span>
        </div>
        <img v-if="ticket.images[0]" :src="ticket.images[0]" />
        <div>
          <span class="title">{{ ticket.title }}</span>
          <!-- 連去商店頁 -->
          <a @click.prevent="$router.push(`/stores/${ticket.storeId}/info`)">{{
            ticket.storeName
          }}</a>
          <span v-if="ticket.updatedTs">{{ tsToDatetime(ticket.updatedTs) }}</span>
          <span v-else>{{ tsToDatetime(ticket.createdTs) }}</span>
        </div>
        <hr />
        <div>
          <span class="subtitle">優惠說明</span>
          <span class="content" v-html="ticket.description"></span>
          <span v-if="ticket.restriction" class="subtitle">注意事項</span>
          <span v-if="ticket.restriction" class="content" v-html="ticket.restriction"></span>
        </div>
      </div>
    </section>
    <!-- 確認popup -->
    <section
      id="popConfirm"
      class="pop notice"
      @click.prevent="hideConfirmPop()"
      v-if="ticket"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <div class="orange">
              <font-awesome-icon
                icon="fa-solid fa-exclamation"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">確定使用{{ ticket.title }}？</span>
          <span>使用後將無法取消<br />請確認已出示給現場人員</span>
        </div>
        <div class="buttons">
          <button @click.prevent="hideConfirmPop()">
            <span>取消</span>
          </button>
          <button @click.prevent="redeemTicket()">
            <span>確定兌換</span>
          </button>
        </div>
      </div>
    </section>
    <!-- 錯誤popup -->
    <section id="popError" class="pop notice" @click.prevent="hideErrorPop()">
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <div class="orange">
              <font-awesome-icon
                icon="fa-solid fa-xmark"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">{{ errorPopContent }}</span>
        </div>
        <div v-if="errorPopContent" class="buttons">
          <button @click.prevent="hideErrorPop()">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';

export default {
  name: 'TicketDetail',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      ticketId: this.$route.params.ticketId,
      ticket: null,
      isLoading: true,
      isRedeemed: false,
      errorPopContent: '',
    };
  },
  mounted() {
    this.getTicket();
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD');
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm');
    },
    callGetTicketAPI() {
      let url = `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets/${this.ticketId}`;
      var config = {
        method: 'get',
        url: url,
        headers: {},
      };
      return this.$http(config);
    },
    getTicket() {
      this.callGetTicketAPI()
        .then((res) => {
          console.log('getTicket res data: ', res.data);
          this.ticket = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.showErrorPop('無法取得票券資訊，請稍後重試');
          console.log(err);
        });
    },
    callRedeemTicketAPI(data) {
      const config = {
        method: 'post',
        url: `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders`,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };
      this.isLoading = true;
      return this.$http(config)
        .then((res) => {
          console.log('redeemTicket res: ', res);
          if (res.data[0].tickets[0].success) {
            this.isRedeemed = true;
          } else {
            throw new Error(`redeem status: ${res.data[0].status}`);
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorPop('兌換失敗，請稍後重試');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    redeemTicket() {
      this.hideConfirmPop();
      const data = JSON.stringify({
        receiverEntityType: 'Store',
        receiverEntityId: this.ticket.storeId,
        tickets: [
          {
            ticketId: this.ticketId,
            barcodeMessage: this.ticket.barcodeMessage,
          },
        ],
      });
      this.callRedeemTicketAPI(data);
    },
    showConfirmPop() {
      popConfirm.style.display = 'flex';
    },
    hideConfirmPop() {
      popConfirm.style.display = 'none';
    },
    showErrorPop(message) {
      this.errorPopContent = message;
      popError.style.display = 'flex';
    },
    hideErrorPop() {
      popError.style.display = 'none';
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
